/* eslint-disable react/no-multi-comp */
import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { graphql, Link } from "gatsby";
import {
  LayoutIndex,
  IndexListing,
  WrapperListing,
  Title,
  Background
} from "../components";
import website from "../../config/website";
import logo from "../logowhite.svg";

const Hero = styled.header`
  display: flex;
`;
const StyledDiv = styled.div`
  height: 200px;
  width: 200px;
  margin: 0 2em;
  cursor: pointer;
  animation: hoverOut 0.8s;

  transition: 0.5s all cubic-bezier(0.215, 0.61, 0.355, 1);
  :hover {
    transform: scale(1.07) rotate(-180deg);
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    height: 180px;
    width: 180px;
  }
  @keyframes hoverOut {
    0% {
      transform: scale(1.07) rotate(-180deg);
    }
    100% {
      transform: scale(1) rotate(0);
    }
  }
`;
const StyledLogo = styled.img`
  height: 200px;
  width: 200px;
  transform: rotate(${props => props.turn}deg);
  transition: 10s all cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-backface-visibility: hidden;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    height: 180px;
    width: 180px;
  }
`;
class Logo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      turn: 0
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState({
      turn: this.state.turn + 180
    });
  }

  render() {
    return (
      <StyledDiv>
        {this.props.children}

        <StyledLogo
          src={logo}
          turn={this.state.turn}
          onClick={this.handleClick}
        />
      </StyledDiv>
    );
  }
}

const Name = styled.h1`
  font-size: 2em;
  margin: 0.23em 0;
  font-weight: 900;
  color: ${props => props.theme.colors.white};
  display: inline-block;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 2.2em !important;
  }
  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    font-size: 1.8em !important;
  }
`;
const HeroInner = styled(WrapperListing)`
  padding-top: 10rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-bottom: 10rem;
  display: flex;
  color: white;
  > div {
    margin: 0 2em;
  }
  @media (max-width: ${props => props.theme.breakpoints.l}) {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding-top: 6rem;
    padding-bottom: 6rem;
    flex-direction: column;
    > div {
      margin: 0 1em;
    }
  }
`;
const HeroContent = styled.div`
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding-top: 2rem;
  }
`;

const HeroLink = styled(Link)`
  color: ${props => props.theme.colors.white};
  transition: 0.2s all cubic-bezier(0.215, 0.61, 0.355, 1);
  animation: backgroundAnimateOut 0.3s;
  animation-fill-mode: forwards;
  text-decoration: none;
  font-weight: 700;
  padding: 0 0.3em;
  :hover {
    opacity: 0.6;
    color: white;
  }
`;

const HeroText = styled.div`
  font-size: 1em;
  line-height: 1.4;
  margin-bottom: 1em;
  p {
    margin-bottom: 1em;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    font-size: 1.4rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 1.25rem;
  }
`;

const Social = styled.ul`
  list-style-type: none;
  flex-wrap: wrap;
  margin-left: 0;
  li a {
      font-style: normal;
      color: ${props => props.theme.colors.greyDarker};
      font-size: 1.333rem;
      font-weight: 400;
      &:hover,
      &:focus {
        color: ${props => props.theme.colors.primary};
        text-decoration: none;
      }
      @media (max-width: ${props => props.theme.breakpoints.s}) {
        font-size: 1.2rem;
      }
    }
  }
`;

const ProjectListing = styled.ul`
  list-style-type: none;
  margin-left: 0;
  padding-left: 1em;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  li {
    margin-bottom: 1.45rem;
    width: 50%;
    padding-right: 1em;
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      width: 100%;
      padding-right: 1em;
    }
    :hover p {
      opacity: 0.8;
    }
    p {
      transition: all 0.2s ease;
    }
    p:first-child {
      transition: all 0.2s ease;
      margin-bottom: 0.3em;
      font-size: 1.777rem;
      font-weight: 500;
      font-style: normal;
      color: ${props => props.theme.colors.white};

      @media (max-width: ${props => props.theme.breakpoints.s}) {
        font-size: 1.777rem;
      }
    }
    p {
      color: ${props => props.theme.colors.white};
      max-width: 600px;
      font-weight: 400;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
  }
`;

const IndexWrapper = styled.main`
  > div {
    margin: 0 auto;
    padding: 0 4em;
    max-width: ${props => props.theme.maxWidth};
    @media (max-width: ${props => props.theme.breakpoints.l}) {
      padding: 0 2em;
    }
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      padding: 0 1em;
    }
  }
`;
const ClickAround = styled.p`
  position: absolute;
  width: 200px;
  height: 100px;
  text-align: center;
  animation: hoverOut 3s;
  cursor: pointer;
  opacity: 0;
  pointer-events: none;
  line-height: 200px;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    height: 180px;
    width: 150px;
    line-height: 200px;
    font-size: 1em;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    height: 180px;
    width: 180px;
    line-height: 180px;
    font-size: 1em;
  }
  z-index: 10;
  @keyframes hoverOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

class Index extends Component {
  render() {
    const {
      data: { homepage, social, posts, projects }
    } = this.props;
    return (
      <LayoutIndex>
        <Hero>
          <HeroInner>
            <Logo />
            <HeroContent>
              <Name>{homepage.data.title.text}</Name>
              <HeroText
                dangerouslySetInnerHTML={{ __html: homepage.data.content.html }}
              />
              <HeroLink to="contact"> Let's get in touch </HeroLink>
            </HeroContent>
          </HeroInner>
        </Hero>
        <IndexWrapper
          id={website.skipNavId}
          style={{ paddingTop: "2rem", paddingBottom: "2rem" }}
        >
          <div>
            <Title style={{ marginTop: "1em" }}>Recent Projects</Title>
            <IndexListing posts={posts.edges} />
            <Title style={{ marginTop: "4rem" }}>
              What I'm currently doing:
            </Title>
            <ProjectListing>
              {projects.edges.map(project => (
                <li key={project.node.primary.label.text}>
                  <a href={project.node.primary.link.url}>
                    <p>{project.node.primary.label.text}</p>
                    <p>{project.node.primary.description.text}</p>
                  </a>
                </li>
              ))}
            </ProjectListing>
          </div>
        </IndexWrapper>
        <Background />
      </LayoutIndex>
    );
  }
}

export default Index;

Index.propTypes = {
  data: PropTypes.shape({
    posts: PropTypes.object.isRequired
  }).isRequired
};

export const pageQuery = graphql`
  query IndexQuery {
    homepage: prismicHomepage {
      data {
        title {
          text
        }
        content {
          html
        }
      }
    }
    social: allPrismicHeroLinksBodyLinkItem {
      edges {
        node {
          primary {
            label {
              text
            }
            link {
              url
            }
          }
        }
      }
    }
    posts: allPrismicPost(sort: { fields: [data___date], order: DESC }) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            thumbnail {
              small {
                url
              }
            }
            date(formatString: "DD.MM.YYYY")
            categories {
              category {
                document {
                  data {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
    projects: allPrismicProjectsBodyLinkItem {
      edges {
        node {
          primary {
            label {
              text
            }
            link {
              url
            }
            description {
              text
            }
          }
        }
      }
    }
  }
`;
